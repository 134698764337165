import React, { useEffect, useState } from "react";
import "./stat.css";
import logo from "../../assets/fcLogo.png";
import CarouselSection from "./carouselSection";

function StatsSection() {
  const stats = [
    { title: "Customers", value: "427,331" },
    { title: "Commodities", value: "+2,588 MT" },
    { title: "Countries", value: "3" },
  ];

  return (
    <div className="statImage" id="about">
      <img src={logo} alt="logo" style={{ width: "112px", height: "112px" }} />

      <div className="statContainer">
        {stats.map((stat) => (
          <div>
            <div className="value">{stat.value}</div>
            <div className="text">{stat.title}</div>
          </div>
        ))}
      </div>
      <p
        className="statText"
      >
        At Farmcrowdy, we have aligned our business models with Sustainable
        Development Goal 2, to end all forms of hunger while promoting food
        security across the globe. Our tech-infused platform drives an enabling
        environment for farmers and sets up more efficient food distribution
        channels. We have also developed two Strategic Business Units, FC Foods
        and FC Shops, that facilitate stakeholder access to maximum
        profitability in the food value chain.
      </p>
    </div>
  );
}

export default StatsSection;
