import React from "react";
import Footer from "./Footer";
import Navbar from "./Home_component/Navbar";

const Privacy = (props) => {
  return (
    <>
      <Navbar />
      <div className="container">
        <div className="terms-privacy">
          <div className="terms_item">
            <h2 className="terms_title"> Privacy Policy </h2>
            <p>
              TThis Privacy Policy governs the manner in which Farmcrowdy
              collects, uses, maintains, stores and discloses Personal Data
              collected from users (each, a “User”, “You” or “Data Subject”) on
              the https://www.farmcrowdy.com website and other platforms
              (“Site”). This privacy policy applies to the Site and all products
              and services offered by Farmcrowdy. We are committed to protecting
              your privacy and the confidentiality of your Personal Data. We
              undertake to preserve the confidentiality of all information you
              provide to us. Our policy complies with the Nigerian Data
              Protection Regulation (NDPR). Kindly read the following carefully
              to understand our views and practices regarding your Personal Data
              and how we will treat it.
            </p>
            <h5>Personal Data</h5>
            <p>
              Personal Data is any information that can be directly used to
              identify you and includes but is not limited to your name,
              address, telephone number, email address and any other information
              of like nature. We may collect Personal Data from you in a variety
              of ways, including, but not limited to, when you visit our site,
              register on the site, place an order, fill out a form, apply for a
              job, and in connection with other activities, services, features
              or resources we make available on our Site. As deemed appropriate,
              You may be asked to provide your Personal Data such as; name,
              email address, mailing address, phone number, account number. You
              may, however, visit our Site anonymously. We collect Personal Data
              from you only if you voluntarily submit such information to us.
              You can always refuse to provide your Personal Data, except that
              it may prevent you from engaging in certain Site related
              activities.
            </p>
            <h5>Non-Personal Identification Information</h5>
            <p>
              We may collect non-Personal Data about you whenever they interact
              with our Site. Non- Personal Data may include your click path, the
              browser name, the type of computer and technical information about
              your means of connection to our Site, such as the operating system
              and other similar information.
            </p>
            <h5>Web Browser Cookies</h5>
            <p>
              Our Site may use “cookies” to enhance your browsing experience.
              User’s web browser places cookies on their hard drive for
              record-keeping purposes and sometimes to track information about
              them. User may choose to set their web browser to refuse cookies,
              or to alert you when cookies are being sent. If they do so, note
              that some parts of the Site may not function properly. Where you
              do not want information collected through the use of cookies,
              there is a simple procedure in most browsers that allows you to
              decline the use of cookies. To learn more about cookies, please
              visit
              <a href="http://www.allaboutcookies.org/">
                http://www.allaboutcookies.org/
              </a>{" "}
            </p>
            <h5>How we use collected Personal Data</h5>
            <p>
              We may collect and use your Personal Data for the following
              purposes:
            </p>
            <ul>
              <li>
                To carry out our obligations arising from any contracts entered
                into or before entering a contract between you and us and to
                provide you with the information, goods and services that you
                request from us.
              </li>
              <li> To notify you about changes to our service.</li>
              <li>
                 To deliver goods and services based on your orders and
                request.
              </li>
              <li>
                <span className="span">To improve our Site-</span>
                We may use feedback you provide to improve our products and
                services.
              </li>
              <li>
                 To work with our business partners in ensuring that we serve
                you better.
              </li>
              <li>
                 To make suggestions and recommendations to you and other users
                about goods or services that may interest you or them.
              </li>
              <li> To carry out recruitment and human capital management.</li>
              <li>
                 To run and operate our Site-We may need your Personal Data to
                display content on the Site correctly.
              </li>
              <li>
               To improve customer service-Information you provide helps us
                respond to your customer service requests and support needs more
                efficiently.
              </li>
              <li>
                {" "}
                 To personalize user experience-We may use information in the
                aggregate to understand how our Users as a group use the
                services and resources provided on our Site.
              </li>
              <li>
                 To improve our Site-We may use feedback you provide to improve
                our products and services.
              </li>
              <li>
                 To process payments-We may use the Personal Data you provide
                us when placing an order only to provide service to that order.
                We do not share this information with outside parties except to
                the extent necessary to provide the service.
              </li>
              <li>
                 To send periodic emails-We may use the email address to send
                you information and updates pertaining to your order. It may
                also be used to respond to your inquiries, questions, and/or
                other requests.
              </li>
            </ul>
            <h5>Lawful Purpose</h5>
            <p>
              Our basis for collecting and processing your Personal Data
              includes but not limited to: contract, legal obligation, consent,
              public interest, vital and legitimate interest. Specifically,
              Personal Data is collected and processed for specific, legitimate
              and lawful purposes:
            </p>
            <ol type="i">
              <li>
                 necessary for us to comply with statutory compliance
                obligations imposed by law or regulatory bodies; or
              </li>
              <li>
                 necessary for the performance of executed or executory
                contracts; or
              </li>
              <li>
               where processing is required for the protection of a Data
                Subject’s vital interests; or{" "}
              </li>
              <li>necessary for our business legitimate interest; or</li>
              <li> consented to by Data Subjects.</li>
            </ol>
            <h5>How we Protect Your Information</h5>
            <p>
              We adopt appropriate data collection, storage, processing
              practices, and security measures to protect against unauthorized
              access, alteration, disclosure or destruction of your Personal
              Data. username, password, transaction information and data stored
              on our Site. Your personal data is secured against all foreseeable
              hazards and breaches such as theft, cyber or viral attack,
              dissemination, manipulations and any damage.
            </p>
            <p>
              Although we use appropriate security measures once we have
              received your Personal Data, the transmission of data over the
              internet (including by e-mail) is never completely secure. We
              endeavour to protect Personal Data, but we cannot guarantee the
              security of data transmitted to us or by us.
            </p>
            <h5>Sharing Your Personal Information</h5>
            <p>
              We do not sell, trade, or rent your Personal Data to others. We
              may share generic aggregated demographic information not linked to
              any personal identification information regarding visitors and
              users with our business partners, trusted affiliates and
              advertisers for the purposes outlined above. We may use third
              party service providers to help us operate our business and the
              Site or administer activities on our behalf, such as sending out
              newsletters or surveys. We may share your Personal Data with these
              third parties for those limited purposes provided that you have
              given us your permission.
            </p>
            <h5>Electronic Newsletters</h5>
            <p>
              If you decide to opt-in to our mailing list, you will receive
              emails that may include company news, updates, related product or
              service information, etc. By using any of our services or
              products, you are also automatically signed up on our mailing
              list. You may decide to opt-out by clicking the unsubscribe clink
              at the footer of our mail to deactivate your subscription. We may
              use third party service providers to help us operate our business
              and the Site or administer activities on our behalf, such as
              sending out newsletters or surveys. We may share your Personal
              Data with these third parties for those limited purposes, provided
              that you have given us your permission.
            </p>
            <h5>Disclosures Required by Law</h5>
            <p>
              We may disclose your Personal Data if required to do so by law or
              in the good faith belief that such action is necessary to (a)
              conform with the requirements of the law or comply with legal
              process served on us, or (b) act in urgent circumstances to
              protect the personal safety of users of our Service or members of
              the public. To the extent practicable and legally permitted, we
              will attempt to advise you prior to any such disclosure, so that
              you may seek a protective order or other relief limiting such
              disclosure.
            </p>
            <h5>Third Party Websites</h5>

            <p>
              Users may find advertising or other content on our Site that link
              to the sites and services of our partners, suppliers, advertisers,
              sponsors, licensors and other third parties. We do not control the
              content or links that appear on these sites and are not
              responsible for the practices employed by websites linked to or
              from our Site. In addition, these sites or services, including
              their content and links, may be constantly changing. These sites
              and services may have their own privacy policies and customer
              service policies. Browsing and interaction on any other website,
              including websites which have a link to our Site, is subject to
              that website’s own terms and policies.
            </p>
            <h5>Retaining Personal Data</h5>
            <p>
              We will retain your Personal Data for as long as your account is
              active or as needed to provide you with our services or comply
              with our legal and statutory obligations.
            </p>
            <h5>Storage of Your Personal Data</h5>
            <p>
              The Personal Data that we collect from you will be kept securely
              to ensure the confidentiality, integrity, and accessibility of
              your Personal Data. We implement a purely cloud-based data storage
              system. By submitting your Personal Data, you agree to this
              transfer, storing or processing. We will take all steps reasonably
              necessary to ensure that your Personal Data is treated securely
              and in accordance with this Privacy Policy.
            </p>
            <p>
              We erase/delete Personal Data in the event of any of the
              following:
            </p>
            <ol>
              <li>
                 The Personal Data is no longer necessary in relation to the
                purposes for which they were collected or processed;
              </li>
              <li>
                 You withdraw your consent or object to the processing and
                there is no overriding lawful basis for the processing;
              </li>
              <li>
                 The personal data was unlawfully collected or processed in
                the first place;
              </li>
              <li>
                 In compliance with NITDA’s or any other lawful directive.
              </li>
            </ol>
            <h5>Your Rights as a Data Subject</h5>
            <p>
              You have various rights in relation to your Personal Data. In
              particular, you have a right to:
            </p>
            <ul>
              <li> to object to the processing of your Personal Data;</li>
              <li>
                 to be informed of how we use and process your Personal Data;
              </li>
              <li> request a copy of your Personal Data we hold about you;</li>
              <li>
                 ask that we update the Personal Data we hold about you, or
                correct such Personal Data that you think is incorrect or
                incomplete;
              </li>
              <li>
                 transfer your Personal Data to a third-party or another
                organisation;
              </li>
              <li>
                 ask that we delete Personal Data that we hold about you, or
                restrict the way in which we use such Personal Data;
              </li>
              <li>
                 withdraw consent to our processing of your Personal Data (to
                the extent such processing is based on consent).
              </li>
            </ul>
            <p>
              To exercise any of your rights, or if you have any other questions
              about our use of your Personal Data, please e-mail
              info@farmcrowdy.com. You may also use this contact detail if you
              wish to make a complaint to us relating to your privacy. If you
              are unhappy with the way we handled your Personal Data or any
              privacy query or request that you have raised with us, you also
              have a right to complaint to the National Information Technology
              Development Agency.
            </p>
            <h5>Problem Signing In</h5>
            <p>
              Our sign-in process is designed to help protect your privacy. If
              you have trouble signing in to our Website, please ensure that you
              are using your registered e-mail address and/or correct password.
              If you are using your registered e-mail address and correct
              password, and you continue to have trouble signing-in to our site,
              please e-mail us immediately at
              <a href="mailto:info@farmcrowdy.com">info@farmcrowdy.com</a>
            </p>
            <h5>Changes to this Privacy Policy</h5>
            <p>
              Farmcrowdy has the discretion to update this Privacy Policy at any
              time. When we do, we will post a notification on the main page of
              our Site. We encourage you to frequently check this page for any
              changes to stay informed about how we are helping to protect the
              Personal Data we collect. You acknowledge and agree that it is
              your responsibility to review this Privacy Policy periodically and
              become aware of modifications.
            </p>

            <h5>Your Acceptance of these Terms</h5>

            <p>
              By using this Site, you signify your acceptance of this Privacy
              Policy. If you do not agree to this policy, please do not use our
              Site. Your continued use of the Site following the posting of
              changes to this policy will be deemed your acceptance of those
              changes. Note that you can withdraw your consent at any time,
              however such withdrawal of consent would not affect the lawfulness
              of processing information based on consent given before its
              withdrawal.
            </p>
            <h5>Breach/Privacy Violation </h5>
            <p>
              In the event of a breach of security leading to the accidental or
              unlawful destruction, loss, alteration, unauthorized disclosure
              of, or access to your Personal Data, Farmcrowdy shall within 72
              (seventy-two) hours of having knowledge of such breach report the
              details of the breach to NITDA. Furthermore, Farmcrowdy shall
              inform the affected Data Subjects of the breach incident, the risk
              to the rights and freedoms resulting from such breach and any
              course of action to remedy the said breach.
            </p>
            <h5>Contacting Us</h5>
            <p>
              Contacting Us If you have any questions, comments or requests
              about this Privacy Policy, the practices of this site, or your
              dealings with this site, please contact us at info@farmcrowdy.com
              or our office at 2, Opeyemisi Bamidele, off Freedom Way, Lekki
              Phase 1 Lagos. This document was last updated on November 25,
              2021.
              <a href="mailto:info@farmcrowdy.com.">info@farmcrowdy.com.</a>
            </p>

            <h5>This document was last updated on November 25, 2021.</h5>
          </div>
        </div>
      </div>

      <Footer hide={true} />
    </>
  );
};
export default Privacy;
