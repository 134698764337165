import React from "react";
import Footer from "./Footer";
import Navbar from "./Home_component/Navbar";

const Terms = (props) => {
  return (
    <>
      <Navbar />
      <div className="container">
        <div className="terms-privacy">
            <div className="terms_item mt-5">
                <h2 className="terms_title"> Terms of use</h2>
                <p>Please read these Terms of Use, which set forth the legally binding terms and conditions for your
                    use of the services (the “Service”) offered by Farmcrowdy Limited. (“Farmcrowdy” or the “Company”)
                    and the website at <a href="https://www.farmcrowdy.com">www.farmcrowdy.com</a>.</p>
                <p>Your access to use of the Service is conditioned on your acceptance of and compliance with these
                    Terms. These Terms apply to all visitors, users and others who access or use the Service and all
                    applicable laws and all conditions or policies referenced here (collectively, the “Terms of Use”)</p>
                <p>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any
                    part of the terms then you may not access the Service and that the Company may use any information
                    the Company obtains about you in accordance with the provisions of the Privacy Policy.</p>
                <h5>Summary of service</h5>
                <p>Farmcrowdy is an agric-tech platform that gives Nigerians the opportunity to participate in the
                    agricultural sector by selecting the kind of farms they want to sponsor. We use the
                    contributors’(“Sponsors”) funds to secure land, engage farmers, plant seeds, insure farmers and
                    farm produce, complete a full farming cycle, sell harvests and then pay the Sponsor profits from a
                    successfully harvested farming cycle. While this farm cycle is on-going, the Sponsors are able to
                    keep track of the full-cycle by getting updates in text, pictures and videos.</p>
                <h5>Definitions</h5>
                <p>
                    In these Terms of Use, we refer to those contributing funds as “Sponsors” and people who have opted
                    in to only receive updates from the farm as “farm followers”. Other users of the Service and
                    visitors to the Site are referred to collectively as “Users.” Information about happenings and
                    progress reports on the farms are referred to as “farm updates”.A “farm cycle” refers to a complete
                    farming season from planting to harvest and sales.</p>
                <h5>Acceptance of terms</h5>
                <p>The Service is offered subject to acceptance of all of the terms and conditions contained in these
                    Terms of Use and all other operating rules, policies, and procedures that may be published on the
                    Site by the Company, which are incorporated by reference, including operating rules, policies, and
                    procedures of third party services providers to the Site that are referenced herein. These Terms of
                    Use apply to every user of the Service. In addition, some Services offered through the Site may be
                    subject to additional terms and conditions adopted by the Company. Your use of those services is
                    subject to those additional terms and conditions, which are incorporated into these Terms of Use by
                    this reference.</p>

                <p>The Company reserves the right, at its sole discretion, to modify or replace these Terms of Use from
                    time to time by posting the updated terms on the Site. It is your responsibility to check the Terms
                    of Use periodically for changes. If you object to any such changes, your sole recourse will be to
                    cease using the Site and the Service. Your continued use of the Service following the posting of
                    any changes to the Terms of Use will indicate your acknowledgement of such changes and agreement to
                    be bound by the terms and conditions of such changes.</p>

                <p>The Company reserves the right to change, suspend, or discontinue the Service (including, but not
                    limited to, the availability of any feature, database, or Content) at any time for any reason. The
                    Company may also impose limits on certain features and Services or restrict your access to parts of
                    or the entire Site without notice or liability.</p>

                <p>By submitting such information, you grant us the right to provide the information to third parties
                    for purposes of facilitating the completion of Purchases.</p>
                <h5> Accounts</h5>

                <p>When you create an account with us, you must provide us information that is accurate, complete, and
                    current at all times. Failure to do so constitutes a breach of the Terms, which may result in
                    immediate termination of your account on our Service.</p>

                <p>You are responsible for safeguarding the password that you use to access the Service and for any
                    activities or actions under your password, whether your password is with our Service or a
                    third-party service.</p>

                <p>You agree not to disclose your password to any third party. You must notify us immediately upon
                    becoming aware of any breach of security or unauthorized use of your account.</p>

                <p>You may not use as a username the name of another person or entity or that is not lawfully available
                    for use, a name or trade mark that is subject to any rights of another person or entity other than
                    you without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.</p>

                <h5>Intellectual Property</h5>

                <p>The Service and its original content, features and functionality are and will remain the exclusive
                    property of Farmcrowdy Limited. The Service is protected by copyright, trademark, and other laws of
                    The Federal Republic of Nigeria. Our trademarks and trade dress may not be used in connection with
                    any product or service without the prior written consent of Farmcrowdy Limited.</p>

                <p>User shall not directly or indirectly: (i) decipher, decompile, disassemble, reverse engineer, or
                    otherwise attempt to derive any source code or underlying ideas or algorithms of any part of the
                    Service, except to the extent applicable laws specifically prohibit such restriction; (ii) modify,
                    translate, or otherwise create derivative works of any part of the Service; or (iii) copy, rent,
                    lease, distribute, or otherwise transfer any of the rights that you receive hereunder.</p>

                <p>If you choose to provide technical, business or other feedback to the Company concerning the Site or
                    the Services (collectively, “Feedback”), the Company will be free to use, disclose, reproduce,
                    license, or otherwise distribute or exploit such Feedback in its sole discretion without any
                    obligations or restrictions of any kind, including intellectual property rights or licensing
                    obligations. You understand and agree that the incorporation by the Company of Feedback into any of
                    its products or services does not grant you any proprietary rights therein.</p>

                <h5>Links to Other Web Sites</h5>

                <p>Our Service may contain links to third-party web sites or services that are not owned or controlled
                    by Farmcrowdy.</p>

                <p>Farmcrowdy has no control over, and assumes no responsibility for, the content, privacy policies, or
                    practices of any third-party web sites or services. You further acknowledge and agree that
                    Farmcrowdy shall not be responsible or liable, directly or indirectly, for any damage or loss
                    caused or alleged to be caused by or in connection with use of or reliance on any such content,
                    goods or services available on or through any such web sites or services.</p>

                <p>We strongly advise you to read the terms and conditions and privacy policies of any third-party web
                    sites or services that you visit.</p>

                <h5>Termination</h5>

                <p>We may terminate or suspend your account immediately, without prior notice or liability, for any
                    reason whatsoever, including without limitation if you breach the Terms.</p>

                <p>Upon termination, your right to use the Service will immediately cease. If you wish to terminate
                    your account, you may simply discontinue using the Service.</p>

                    <h5>Indemnification</h5>

                <p> shall defend, indemnify, and hold harmless Farmcrowdy, its affiliates and each of its, and its affiliates employees, contractors, directors, suppliers and representatives from all liabilities, claims, and expenses, including reasonable attorneys’ fees, that arise from or relate to your use or misuse of, or access to, the Site, Service, Content or otherwise from your User Submissions, violation of the Agreement, or infringement by you, or any third party using the your account, of any intellectual property or other right of any person or entity. Farmcrowdy reserves the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will assist and cooperate with Farmcrowdy in asserting any available defenses.</p>

                <h5>Limitation Of Liability</h5>

                <p>In no event shall Farmcrowdy, its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.</p>

                <h5>Disclaimer</h5>

                <p>Your use of the Service is at your sole risk. The Service is provided on an “AS IS” and “AS AVAILABLE” basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.</p>

                <p>Farmcrowdyits subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or d) the results of using the Service will meet your requirements.</p>

                <h5>Governing Law</h5>

                <p>These Terms shall be governed and construed in accordance with the laws of The Federal Republic of Nigeria, without regard to its conflict of law provisions.</p>

                <p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service.</p>

                

                <h5>Changes To Terms</h5>

                <p>Farmcrowdy reserves the right, in its sole discretion, to change the Terms under which farmcrowdy.com is offered. The most current version of the Terms will supersede all previous versions. Farmcrowdy encourages you to periodically review the Terms to stay informed of our updates.</p>

                <h5>Contact Us</h5>

                <p>If you have any questions about these Terms, please contact us at: <a href="mailto:info@farmcrowdy.com">info@farmcrowdy.com</a>.</p>
                <h6>Lagos Office:</h6>
                <p>2, Opeyemisi Bamidele Crescent,<br /> Off Freedom Way,<br /> Ikate, Lekki Phase 1<br />
                Lagos, Nigeria.</p>

                <h6>Abuja Office:</h6>
                <p>Suite 3, 4th floor, Sinoki House,<br /> Plot 770 Off Samuel Ademulegun Ave,<br /> Central Business District,<br /> Abuja, Nigeria</p>
                <h5>This document was last updated on November 2, 2018</h5>
            </div>
        </div>
    </div>
    
      <Footer hide={true} />
    </>
  );
};
export default Terms;
