import React, { useState } from "react";
import "./index.css";
import fclogowhite from "../../images_fc4/fclogo.svg";
import SideNav from "react-simple-sidenav";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Navbar = () => {
  const [state, setState] = React.useState({
    NavisOpen: false,
    theUserIsLoggedIn: false,
    isloading: false,
    user_details: {},
  });
  const [showNav, setShowNav]: any = useState(false);
  const { NavisOpen, isloading, user_details, drop_down_open }: any = state;
  return (
    <>
      <div className="nav_1">
        <div className="nav_2 flex justify-between">
          <div className="ffrst">
            Nigeria’s First Digital Agriculture Platform
          </div>
          <div className="ffrst1">
            {" "}
            <a href="tel:+234 (0) 706 796 3016">
              {" "}
              Helpline: +234 (0) 706 796 3016
            </a>
          </div>
        </div>
        <div className="nav3 flex align-center">
          <div className="ffrst">
            <HashLink smooth to="/#">
              <img src={fclogowhite} alt="fclogowhite" />
            </HashLink>
          </div>
          <div className="flex nav4 nav4a" style={{ fontSize: "14px" }}>
            <div className="navitem">
              <HashLink smooth to="/#">
                {" "}
                Home
              </HashLink>
            </div>
            <div className="navitem">
              <HashLink to="/about"> About Us</HashLink>
            </div>
            <div className="navitem">
              {" "}
              <a
                href="https://farmcrowdystories.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Stories
              </a>{" "}
            </div>
            <div className="navitem">
              <HashLink smooth to="/contact#">
                Contact
              </HashLink>
            </div>
            <span className="nav_sep">|</span>
            <div className="nav5 lemon">
              <HashLink smooth to="/fcshop">
                FC Shops
              </HashLink>
            </div>
            <div className="nav5 lemon">
              <HashLink smooth to="/fcfood">
                FC Foods
              </HashLink>
            </div>
          </div>
          <div
            className="mobileham"
            onClick={() => {
              setShowNav({
                NavisOpen: NavisOpen ? false : true,
              });
            }}
          >
            {!NavisOpen ? (
              <>
                <div className="ham1 animated slideInLeft"></div>
                <div className="ham2 animated slideInLeft"></div>
                <div className="ham3 animated slideInLeft"></div>
              </>
            ) : (
              <span className="nvtimes animated slideInLeft">&times;</span>
            )}
          </div>
        </div>
      </div>
      <SideNav
        showNav={showNav}
        style={{ background: showNav ? "rgba(251, 251, 251, 1)" : "none" }}
        navStyle={{ width: "70%", background: "rgba(251, 251, 251, 1)" }}
        onHideNav={() => setShowNav(false)}
        titleStyle={{
          backgroundColor: "#fff",
          color: "#444444",
          paddingLeft: 10,
          paddingBottom: 0,
          paddingTop: 0,
          fontSize: 17,
          textAlign: "left",
        }}
        itemHoverStyle={{
          backgroundColor: "rgba(251, 251, 251, 1) !important",
        }}
        itemStyle={{ backgroundColor: "" }}
        title={[
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "18px 10px 18px 17px",
            }}
          >
            <img src={fclogowhite} alt="logo" className="logo2" />
            <span className="fa closemenu" onClick={() => setShowNav(false)}>
            &#215;
            </span>
          </div>,
        ]}
        items={[
          <div className="otherwrap mobileshow1">
            <div className="otherwrap23 otherwrap23admin">
              {/* <Link to={"/"} className="flex-12a">
                {" "}
                <span
                  className={
                    window.location.pathname == "/#/"
                      ? "navlink_is_active lightorange  "
                      : "navlink_is_active1"
                  }
                >
                  <div className="special">Home</div>
                </span>
              </Link> */}
              <HashLink smooth to="/#" onClick={() => setShowNav(false)}>
                {" "}
                Home
              </HashLink>
              <br />
              <br />
              <HashLink smooth to="/#about" onClick={() => setShowNav(false)}>
                {" "}
                About Us
              </HashLink>
              <br />
              <br />
              <HashLink smooth to="/#contact" onClick={() => setShowNav(false)}>
                {" "}
                Contact
              </HashLink>
              <br />
              <br />
              <div className="navitem">
                {" "}
                <a
                  href="https://farmcrowdystories.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Stories
                </a>{" "}
              </div>
              <br />
              <br />
              <div className="nav5 lemon" onClick={() => setShowNav(false)}>
                <HashLink smooth to="/fcshop">
                  FC Shops
                </HashLink>
              </div>
              <br />
              <div className="nav5 lemon" onClick={() => setShowNav(false)}>
                <HashLink smooth to="/fcfood">
                  FC Foods
                </HashLink>
              </div>
              {/* <br />
              <br />
              <HashLink smooth to="/#about"> About Us</HashLink>
              <br />
              <br />
              <HashLink smooth to="/#about"> About Us</HashLink> */}
              {/* <Link to={"/about"} className="flex-12a">
                {" "}
                <span
                  className={
                    window.location.pathname == "/about"
                      ? "navlink_is_active lightorange"
                      : "navlink_is_active1"
                  }
                >
                  <div className="speialcl">About us</div>
                </span>
                {window.location.pathname == "/about" && (
                  <span className="boxdes"></span>
                )}
              </Link> */}
            </div>
          </div>,
        ]}
      />
    </>
  );
};
export default Navbar;
