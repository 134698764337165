import React, { Component } from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import "./App.css";
import Home from "./Components/Home";
import Contact from "./Components/Contact";
import About from "./Components/About";
import FCSHOP from "./Components/fcshop";

import Terms from "./Components/Terms";
import Privacy from "./Components/Privacy";
import FCFOOD from './Components/fcfood';

class App extends Component {
  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <Switch>
            <Route component={About} exact={true} path="/about" />
            <Route component={FCSHOP} exact={true} path="/fcshop" />
            <Route component={FCFOOD} exact={true} path="/fcfood" />
            <Route component={Contact} path="/contact" />
            <Route component={Terms} path="/terms" />
            <Route component={Privacy} path="/privacy" />
            <Route component={Home} path="/" />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
