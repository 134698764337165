import React, { useRef } from "react";
import "./carousel.css";

import "slick-carousel/slick/slick.css";

import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import farmer21 from "../../assets/farmer21.png";
import farmer22 from "../../assets/farmer22.png";
import farmer23 from "../../assets/farmer23.png";
import businessDay from "../../assets/bussinessDay.png";
import thisday from "../../assets/thisDay.png";
import techCrunch from "../../assets/techCruch.png";
import techNext from "../../assets/techNext.png";
import left from "../../assets/leftArr.png";
import right from "../../assets/rightArr.png";

function CarouselSection() {
  const sliderRef = useRef();
  const next = () => {
    sliderRef.current.slickNext();
  };
  const previous = () => {
    sliderRef.current.slickPrev();
  };
  const settings = {
    className: "slider variable-width",
    dots: false,
    infinite: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 5000,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          className: "slider variable-width",
          dots: false,
          infinite: true,
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          //   initialSlide: 1,
          centerMode: false,
          variableWidth: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          //   initialSlide: 1,
          centerMode: false,
          variableWidth: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          //   initialSlide: 1,
          centerMode: false,
          variableWidth: false,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          //   initialSlide: 1,
          centerMode: false,
          variableWidth: false,
        },
      },
    ],
  };

  const images = [
    {
      image:farmer21,
      title: 'PRODUCTS',
      header: 'Pioneers in Aggregation for Commodities',
      desc: 'We are poisitoned to be the leader, reshaping Agro Commodity trading through creative, profitable and value adding investment vehicles.'
    },
    {
      image:farmer22,
      title: 'RWANDA',
      header: 'Access to Funds for Select Farmers',
      desc: 'In our bid to aid the farmer in their financial deficiency, we embarked on an initative to provide access to funds for some select farmers in Musanze.'
    },
    {
      image:farmer23,
      title: 'MAKING IMPACT',
      header: 'Solving the Inefficiencies in the food value chain',
      desc: 'We are brand known to build impact and sustainable business solutions, providing value to stakeholders.'
    },
  ];

  const mediaMentions = [
    {
      date: "08/09/2021",
      text: "Youth-led, African Agribusinesses Announced as Pitch AgriHack 2021 Winners",
      subText:
        "Farmcrowdy is runner up under the Mature- and Growth-Stage category",
      image: businessDay,
      link:'https://www.africanews.com/2021/09/08/youth-led-african-agribusinesses-announced-as-pitch-agrihack-2021-winners/'
    },
    {
      date: "18/11/2021",
      text: "Farmcrowdy Launches New Agro-focused Trading Platform",
      subText:
        "Farmcrowdy is runner up under the Mature- and Growth-Stage category",
      image: thisday,
      link:'https://www.thisdaylive.com/index.php/2020/11/18/farmcrowdy-launches-new-agro-focused-trading-platform/'
    },
    {
      date: "22/03/2019",
      text: "Farmcrowdy raises $1M round to bring Nigerian farmers online and to market",
      subText:
        "Bringing small scale Nigerian farmers to a broader market has netted the new startup Farmcrowdy $1 million.",
      image: techCrunch,
      link:'https://techcrunch.com/2017/12/18/1579210/'
    },
    {
      date: "01/06/2021",
      text: "Onyeka Akumah steps down as Akindele Phillips becomes new Farmcrowdy CEO",
      subText:
        "Farmcrowdy is runner up under the Mature- and Growth-Stage category",
      image: techNext,
      link:'https://technext.ng/2021/06/01/onyeka-akumah-steps-down-as-akindele-phillips-becomes-new-farmcrowdy-ceo/'
    }
  ];
  return (
    <div style={{ position: "relative" }}>
      <div className="slider">
        <Slider ref={sliderRef} {...settings}>
          {images.map((image, index) => (
            <div>
              <div className="carousel carousel-slider2">
                <div className="content">
                  <div className="contentText">
                    <p>{image.title}</p>
                    <h3>{image.header}</h3>
                    <div className="text">{image.desc}</div>
                    {/* <div className="learn">Learn More</div> */}
                  </div>
                  <img src={image.image} alt="farmer" />
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <img onClick={previous} className="left" src={left} alt="farmer" />
        <img onClick={next} className="right" src={right} alt="farmer" />
      </div>

      <div className="mediaMentions">
        <div className="title">Media mentions</div>
        <div className="subTitle">Farmcrowdy in the Press</div>
        <div className="text">
          We have garnered thousands of media mentions, recognitions and awards
          since we launched in 2016. See some of these here.
        </div>
        <div className="mentionContainer">
          {mediaMentions.map((data, index) => (
            <div>
              <div className="mediaCarousel">
                <div className="mediaContent">
                  <div className="mediaContentText">
                    <p>{data.date}</p>
                    <h3>{data.text}</h3>
                    <div className="text">{data.subText}</div>
                    <img src={data.image} alt="media" />
                    <div className="learn"> <a href={data.link} target="_blank" rel="noopener noreferrer">Read Full Article</a></div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CarouselSection;
