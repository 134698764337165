import React from "react";
import Footer from "./Footer";
import Navbar from "./Home_component/Navbar";
import Home_Section1 from "./Home_component/Home_Section1";
import FCShop_FCfood from "./Home_component/FCShop_FCfood";
import CarouselSection from "./Home_component/carouselSection";
import StatsSection from "./Home_component/statsSection";

const Home = () => {
  return (
    <>
      <Navbar />
      <Home_Section1 />
      <StatsSection />
      <CarouselSection />
      <FCShop_FCfood />
      <Footer />
    </>
  );
};
export default Home;
