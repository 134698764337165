import React, { useState } from "react";
import "./index.css";
import "./animate.css";
import shop from "../../images_fc4/shop.png";
import fcshop from "../../images_fc4/fcshop.png";
import fcfood from "../../images_fc4/fcfood.png";
import foods from "../../images_fc4/foods.png";
import { Link } from "react-router-dom";

const FCShop_FCfood = () => {
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="row pdlft pdlft3">
            <div className="col-md-6 text-right pdright" id="shop">
              <Link to="/fcshop">
                <img src={shop} className="slp" alt="" />
              </Link>
            </div>
            <div className="col-md-5 colm2 text-left">
              <div className="fcshopa">
                <img src={fcshop} alt="fcshop" className="fcshop__" />
              </div>
              <div className="tech3">
                Harnessing the power of technology to solve Nigeria’s informal
                retail distribution problem
              </div>
              <div className="tech34">
                FC Shops is a tech-enabled B2B retail platform that helps small
                retailers source their inventory by facilitating access to the
                widest assortment of FMCG goods at wholesale prices. FC Shops
                brings the market to your doorstep.
              </div>
              {/* <div className="learnmore">Learn More</div> */}
            </div>
          </div>
          <div className="row pdlft pdlft3 pdlfx justify-center">
            <div className="col-md-6 colm3 text-left" id="food">
              <div className="fcshopa">
                <Link to="/fcfood">
                  <img src={foods} alt="fcshop" className="fcshop__" />
                </Link>
              </div>
              <div className="tech3">
                Strengthening the local food supply chain
              </div>
              <div className="tech34">
                FC Foods is a one-stop digital marketplace for trading raw
                agro-products, inputs and commodities. Consumers can
                conveniently purchase fresh foods products and groceries. Our
                licensed abattoir also enables us to efficiently deliver
                hygienic livestock to our consumers.
              </div>
              {/* <div className="learnmore">Learn More</div> */}
            </div>
            <div className="col-md-5 text-left pdright">
              <img src={fcfood} className="fcfood" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FCShop_FCfood;
