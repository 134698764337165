import React, { useState } from "react";
import Footer from "./Footer";
import Navbar from "./Home_component/Navbar";
import fcshop from "../images_fc4/aboutfcshop.png";
import fcshopgroup from "../images_fc4/fcshopgroup.png";
import fcshopimage from "../images_fc4/fcshopimage.png";
import fcshop1 from "../images_fc4/fcshop1.png";
import hero2 from "../images_fc4/fcgroup.svg";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import YouTube from "react-youtube";

const FCSHOP = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  window.scrollTo(-0, -0);
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };
  const _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };
  React.useEffect(() => {
    window.scrollTo(-0, -0);
  }, []);
  return (
    <>
      <Navbar />
      <div className="row firstcontactarea firstshoparea">
        <div className="col-md-12 firstcontactarea2">
          <div className="col-md-12">
            <div className="row rtail2 evenpaddinng">
              <img src={fcshopimage} className="fcshopimage" alt="" />
              <div className="col-md-4 rtail1">
                <div>
                  <img src={fcshop1} className="fcshop23" alt="fcshop" />
                </div>
                A Tech-enabled B2B Retail Platform.
              </div>
              <div className="col-md-8 rtail">
                FC Shops is a tech-enabled B2B retail platform that helps small
                retailers source their inventory, by giving access to the widest
                assortment of FMCG goods at wholesale prices. FC Shops brings
                the market to your doorstep.
              </div>
            </div>
          </div>
          <div className="txt3 evenpaddinng"></div>
        </div>
      </div>
      <div className="row mm1">
        <div className="col-md-12">
          <div className="b1">More about us</div>
          <div className="b12 darktext">What FC Shops is all about</div>
        </div>
        <div className="col-md-12 fcshop1 evenpaddinng">
          <img
            src={fcshop}
            alt=""
            className="fcshop img-fluid"
            onClick={handleShow}
          />
        </div>
        <div className="col-md-12 mm12 mxe4">
          <div className="text-center b12 ">
            FC Shops delivers processed foods and products to SMEs and MSMEs in
            the retail chain by enhancing an effortless distribution of goods at
            the best price. With FC Shops, businesses can conveniently bank on
            us for a seamless supply chain management from start to finish.
          </div>
        </div>
        <div className="col-md-12 mm12">
          <div className="b12 darktext">
            Harnessing the power of technology to solve Nigeria’s informal
            retail distribution problem
          </div>
        </div>
        <div className="col-md-12 evenpaddinng nopaddinng232">
          <img
            src={fcshopgroup}
            alt="fcshopgroup"
            className="img-fluid fcshopgroup1 "
          />
        </div>
      </div>
      <div className="row col23a">
        <div className="col-md-12 eve34 evenpaddinng">
          <div className="row maxwidth1">
            <div className="col-md-4 leftpaddinge">
              <div className="know12">Want to know more?</div>
              <div className="know123">
                We’ll be happy to hear from you for any inquiry or feedback. A
                representative is ready to hear you out.
              </div>
              <div className="">
                <Link to="/contact">
                  {" "}
                  <button className="getingtouch1">Get in Touch</button>
                </Link>
              </div>
            </div>
            <div className="col-md-4">
              <img src={hero2} className="hero2" alt="" />
            </div>
          </div>
        </div>
      </div>
      <Footer hide={true} />
      <Modal show={show} onHide={handleClose} size={"lg"} center={true}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <YouTube videoId="XTg1dGX_lwg" className="yubs" onReady={_onReady} />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default FCSHOP;
