import React from "react";
import Footer from "./Footer";
import Navbar from "./Home_component/Navbar";

const Contact = (props) => {
  React.useEffect(()=>{
    window.scrollTo(-0, -0);
  },[])
  return (
    <>
      <Navbar />
      <div className="row firstcontactarea">
        <div className="col-md-12 firstcontactarea2">
          <div className="txt3 evenpaddinng">
            Get in touch <br /> with us
          </div>
        </div>
      </div>
      <div className="row evenpaddinng">
        <div className="col-md-6 custome2">
          Please contact us using this form if you have any questions or
          comments about Farmcrowdy.
        </div>{" "}
        <div className="col-md-6 contt232 contt232_new">
          <form
            action="https://formsubmit.co/info@farmcrowdy.com"
            method="POST"
          >
            <div className="row contt232">
              <div className="col-md-12 col-sm-12 custom2">
                <h6>Full name</h6>
                <input
                  type="text"
                  name="fullname"
                  className="form-control custom3x formcemail"
                  required
                />
              </div>
              <div className="col-md-12 col-sm-12 custom2">
                <h6>Email</h6>
                <input
                  type="text"
                  placeholder=""
                  name="email"
                  className="form-control custom3x formcemail"
                  required
                />
              </div>
              <div className="col-md-12 col-sm-12 custom2">
                <h6>Phone Number</h6>
                <input
                  type="text"
                  placeholder=""
                  name="phone"
                  className="form-control custom3x formcemail"
                  required
                />
              </div>
              <div className="row interestedin">
                <div className="col-md-6 col-sm-6 custom21">
                  <h6>I am</h6>
                  <select
                    placeholder=""
                    name="iam"
                    className="form-control custom3x formcemail"
                    required
                  >
                    <option>Select an option</option>
                    <option value="An Agro Buyer">An Agro Buyer</option>
                    <option value="An Agro Seller">An Agro Seller</option>
                    <option value="A Processor">A Processor</option>
                    <option value="A Farmer">A Farmer</option>
                    <option value=" An Investor">An Investor</option>
                    <option value=" A Manufacturer">A Manufacturer</option>
                    <option value="Others">Others</option>
                  </select>
                </div>
                <div className="col-md-6 col-sm-6 custom212">
                  <h6>Interested in</h6>
                  <select
                    placeholder=""
                    name="iam"
                    className="form-control custom3x formcemail"
                    required
                  >
                    <option>Select an option</option>
                    <option value="Buying">Buying</option>
                    <option value="Selling">Selling</option>
                    <option value="Information">Information</option>
                    <option value="Data">Data</option>
                    <option value="Projects">Projects</option>
                    <option value="Others">Others</option>
                  </select>
                </div>
              </div>
              <div className="col-md-12 col-sm-12 custom2">
                <h6>Message</h6>
                <textarea
                  placeholder=""
                  name="message"
                  className="form-control custom3x formcxx1"
                  required
                ></textarea>
              </div>
              <div className="col-md-12 col-sm-12 custom2">
                <button type="submit" className="sbttnP">
                  Submit
                </button>
              </div>
            </div>
          </form>
          <div className="body_home"></div>
        </div>
      </div>
      <Footer hide={true} />
    </>
  );
};
export default Contact;
