import React, { useState,useRef } from "react";
import "./index.css";
import "./animate.css";
import Slider from "react-slick";
import bg1 from "../../assets/bg1.jpg";
import bg2 from "../../assets/bg2.jpg";
import bg3 from "../../assets/bg3.jpg";
import bg4 from "../../assets/bg4.jpg";
import home_style from "./home_carousel.css";

import "slick-carousel/slick/slick.css";

import "slick-carousel/slick/slick-theme.css";
import left from "../../assets/leftArr.png";
import right from "../../assets/rightArr.png";


const Home_Section1 = () => {
  const sliderRef = useRef();
  const next = () => {
    sliderRef.current.slickNext();
  };
  const previous = () => {
    sliderRef.current.slickPrev();
  };
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 5000,
  };

  const contents = [
    {
      image:bg1,
      title: 'PRODUCTS',
      header: 'Building Sustainable Food Solutions in Africa through Technology',
      desc: 'Using innovation and harnessed data, we create enabling environments for key players in the food value chain to maximize output and yield untapped profits.'
    },
    {
      image:bg2,
      title: 'RWANDA',
      header: 'Providing a wide range of agriculture commodities to world markets',
      desc: 'We trade in a wide range of commodity products domestically and internationally from its trading offices strategically placed throughout the country.'
    },
    {
      image:bg3,
      title: 'MAKING IMPACT',
      header: 'Supplying food produce to businesses in the most hygenic form',
      desc: 'We are playing a crucial role in the 40% contribution of the global value of agricultural output and supports the livelihoods and food and nutrition security.'
    },
    {
      image:bg4,
      title: 'MAKING IMPACT',
      header: 'Delivering Value for retailers, distributors & agents',
      desc: 'We are looking out for businesses who seek the best distribution channel between manufacturers and their end customers.'
    },
  ];

  return (
    <div style={{ position: "relative" }}>
      <div className="slider">
        <Slider ref={sliderRef} {...settings}>
          {contents.map((content, index) => (
            <div>
              <div className="carousel carousel-slider1">
                <div className="homeContent">
                  <div className="homeContentText">
                    {/* <p>{content.title}</p> */}
                    <h3>{content.header}</h3>
                    <div className="text">{content.desc}</div>
                    {/* <div className="learn">Learn More</div> */}
                  </div>
                  <img src={content.image} alt="farmer" />
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <img onClick={previous} className="homeLeft" src={left} alt="farmer" />
        <img onClick={next} className="homeRight" src={right} alt="farmer" />
      </div>
    </div>
  );
};
export default Home_Section1;
