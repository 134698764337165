import React, { useState } from "react";
import Footer from "./Footer";
import Navbar from "./Home_component/Navbar";
// import fcshop from "../images_fc4/aboutfcshop.png";
// import fcshopgroup from "../images_fc4/fcshopgroup.png";
// import fcshopimage from "../images_fc4/fcshopimage.png";
// import fcshop1 from "../images_fc4/fcshop1.png";
import hero2 from "../images_fc4/fcgroup.svg";
import { Link } from "react-router-dom";
import hero3 from "../images_fc4/g5.png";
import hero4 from "../images_fc4/food.png";
import hero5 from "../images_fc4/Frame 7.png";
import g7 from "../images_fc4/g7.png";
import { Modal } from "react-bootstrap";
import YouTube from "react-youtube";

const FCFOOD = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  React.useEffect(() => {
    window.scrollTo(-0, -0);
  }, []);
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };
  const _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };
  return (
    <>
      <Navbar />
      <div className="row firstcontactarea firstshoparea">
        <div className="col-md-12 firstcontactarea2">
          <div className="col-md-12">
            <div className="row rtail2 evenpaddinng">
              <img src={hero4} className="fcshopimage" alt="" />
              <div className="col-md-4 rtail1">
                <div>
                  <img src={hero5} className="fcshop23" alt="fcshop" />
                </div>
                A One-stop Digital Marketplace for Trading.
              </div>
              <div className="col-md-8 rtail">
                FC Foods is a one-stop digital marketplace for trading raw
                agro-products, inputs and commodities. Consumers can
                conveniently purchase fresh foods products and groceries. Our
                licensed abattoir also enables us to efficiently deliver
                hygienic livestock to our consumers.
              </div>
            </div>
          </div>
          <div className="txt3 evenpaddinng"></div>
        </div>
      </div>
      <div className="row mm1">
        <div className="col-md-12">
          <div className="b1">More about us</div>
          <div className="b12 darktext">What FC Foods is all about</div>
        </div>
        <div className="col-md-12 fcshop1 evenpaddinng" onClick={handleShow}>
          <img src={g7} alt="" className="fcshop img-fluid" />
        </div>
        <div className="col-md-12 mm12 mxe4">
          <div className="text-center b12 ">
            FC Foods enables access to markets for Farmers by linking them to
            premium buyers in the B2B line of trade using technology.
            Stakeholders in the food value chain can also gain access to farm
            inputs, processed agro-products and fresh food products. At FC
            Foods, we are solving the food accessibility problem in the food
            value chain while promoting food security across Africa.
          </div>
        </div>
        <div className="col-md-12 mm12">
          <div className="b12 darktext">
            Strengthening the local food supply chain
          </div>
        </div>
        <div className="col-md-12 evenpaddinng nopaddinng232">
          <img
            src={hero3}
            alt="fcfoodgroup"
            className="img-fluid fcshopgroup1 "
          />
        </div>
      </div>
      <div className="row col23a">
        <div className="col-md-12 eve34 evenpaddinng">
          <div className="row maxwidth1">
            <div className="col-md-4 leftpaddinge">
              <div className="know12">Want to know more?</div>
              <div className="know123">
                We’ll be happy to hear from you for any inquiry or feedback. A
                representative is ready to hear you out.
              </div>
              <div className="">
                <Link to="/contact">
                  {" "}
                  <button className="getingtouch1">Get in Touch</button>
                </Link>
              </div>
            </div>
            <div className="col-md-4">
              <img src={hero2} className="hero2" alt="" />
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} size={"lg"} center={true}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <YouTube videoId="GWi78xL_b1I" className="yubs" onReady={_onReady} />
        </Modal.Body>
      </Modal>
      <Footer hide={true} />
    </>
  );
};
export default FCFOOD;
