import React from "react";
import style from "./index.module.scss";
import data from "../../../assets/data.png";
import pricing from "../../../assets/pricing.png";
import yieldProfit from "../../../assets/yield.png";
import gps from "../../../assets/gps.png";
import stories from "../../../assets/stories.png";

const Data = () => {
  return (
    <div>
      <div className={style["tech--wrapper"]}>
        <p className={style["top-text"]}>Technology & Data</p>
        <h3 className={style["tech--title"]}>
          A technology-first approach to seed, input <br /> and agronomic
          analysis and sourcing
        </h3>
        <p className={style["tech-subtext"]}>
          We give farmers tools to help them purchase only the best seeds and
          input, optimize production decisions, identify yield <br /> and profit
          opportunities, and map their farmlands amongst other services, all
          automatically.
        </p>
        <div className={style["tech--list"]}>
          <div>
            <img src={data} alt="Data and Market analysis tools" />
            <p>Data and Market analysis tools</p>
          </div>
          <div>
            <img src={pricing} alt="Pricing and profitability tools" />
            <p>Pricing decision making tools for farmers</p>
          </div>
          <div>
            <img src={yieldProfit} alt="Yield and profitability tools" />
            <p>Yield Projection and Optimization</p>
          </div>
          <div>
            <img src={gps} alt="GPS tools" />
            <p>GPS and Weather data tracking</p>
          </div>
        </div>
      </div>
      <div className={style["farmcrowdy--stories"]}>
        <div className={style["stories--section"]}>
          <div>
            <img src={stories} alt="stories" />
          </div>
          <div className={style["text--sec"]}>
            <p>
              Catch up on the latest Farmcrowdy news and delve into how we’re
              driving innovation and change through our blog series.
            </p>
            <a className={style["anchor"]} href="https://farmcrowdystories.com/" target='_blank'>
              Go to Farmcrowdy Stories
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Data;
